import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PricesService } from 'src/app/services/prices.service';

@Component({
  selector: 'app-template',
  templateUrl: './minisite.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MinisiteComponent implements OnInit {
  itinerary: any;
  showMobileMenu: boolean;
  location;
  initLazyLoad = false;

  constructor(private _activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef, private pricesService:PricesService, private zone: NgZone) {
    const data = this._activatedRoute.snapshot.data;
    this.location = document.location.href;
    this.itinerary = this.pricesService.init(data.itinerary);
    console.log(this.itinerary);
  }

  trackByFn(index, item)
  {
    return index;
  }

  ngOnInit() {
  }

  ngAfterViewInit()
  {
    var event = new CustomEvent('vtbInited');
    window.dispatchEvent(event);

    this.zone.runOutsideAngular(() => {
      window.addEventListener('scroll', (event) => {
        if(!this.initLazyLoad) {
          let top = event.target['scrollingElement'].scrollTop;
          if(top > 400) {
            this.initLazyLoad = true;
            this.cdr.detectChanges();
          }
        }
      });
    });
  }

  // setRequired(blockID, lineID) 
  // {
  //   this.itinerary = this.pricesService.setRequired(this.itinerary, blockID, lineID);
  //   console.log(this.itinerary);
  //   this.cdr.detectChanges();
  // }


}
